<template>
  <div>
    <v-form ref="form2" v-model="valid" lazy-validation>
      <v-dialog v-model="showForm" max-width="700px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }} </span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="editedItem.departement_id"
                    :items="departements"
                    item-text="nom_departement"
                    item-value="id"
                    :rules="[(v) => !!v || 'Departement obligatoire']"
                    label="Departement"
                    dense
                    @change="dept_change"
                    :readonly="!modify"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="editedItem.ville_id"
                    :items="villes"
                    item-text="nom_ville"
                    item-value="id"
                    label="Ville"
                    dense
                    :rules="[(v) => !!v || 'Ville obligatoire']"
                    :readonly="!modify"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.label"
                    label="Libelle"
                    :rules="[(v) => !!v || 'Libelle obligatoire']"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    outlined
                    type="text"
                    label="Adresse"
                    :rules="[(v) => !!v || 'Adresse obligatoire']"
                    v-model="editedItem.adresse"
                    :value="editedItem.adresse"
                    required
                    :readonly="!modify"
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.email_pro"
                    label="E-mail Professionnel"
                    :rules="[
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        'E-mail non valide',
                    ]"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.email_perso"
                    label="E-mail Personnel"
                    :rules="[
                      (v) =>
                        !v ||
                        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                        'E-mail non valide',
                    ]"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.tel"
                    label="Tel"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.mobile1"
                    label="Mobile 1"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.mobile2"
                    label="Mobile 2"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="4">
                  <v-text-field
                    autocomplete="off"
                    outlined
                    dense
                    v-model="editedItem.gps"
                    label="Coordonnées GPS"
                    :readonly="!modify"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              :disabled="!valid"
              @click="save"
              v-if="modify"
            >
              Enregistrer
            </v-btn>
            <v-btn color="blue darken-1" text @click="close"> Fermer </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </div>
</template>

<script>
import CREATE_ADRESS from "../graphql/Adress/CREATE_ADRESS.gql";
import UPDATE_ADRESS from "../graphql/Adress/UPDATE_ADRESS.gql";

export default {
  name: "adresses",
  props: {
    item: Object,
    showForm: Boolean,
    tier_id: Number,
    addresses: Array,
    modify: Boolean,
    departements: Array,
  },

  data: () => ({
    valid: true,
    villes: [],
    editedItem: {},
    defaultItem: {
      id: -1,
      adresse_type: "Livraison",
      adresse: "",
      ville_id: "",
      departement_id: "",
    },
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
  }),

  computed: {
    formTitle() {
      return this.editedItem.id == -1
        ? "Nouvelle Adresse"
        : "Modifier L'adresse";
    },
  },

  watch: {},

  created() {},
  mounted() {
    if (this.item) {
      if (this.item.id > 0) {
        this.editedItem = Object.assign({}, this.item);
        this.villes = this.select_ville(this.editedItem.departement_id);
      } else {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.$refs.form2.resetValidation();
      }
    }
  },
  methods: {
    select_ville(departement_id) {
      return this.departements[
        this.departements.findIndex((h) => h.id === departement_id)
      ].villes;
    },
    close() {
      this.$emit("close");
    },

    save() {
      if (this.$refs.form2.validate()) {
        if (this.editedItem.id > -1) {
          this.$apollo
            .mutate({
              mutation: UPDATE_ADRESS,
              variables: {
                adress: {
                  id: this.editedItem.id,
                  ville_id: this.editedItem.ville_id,
                  adresse: this.editedItem.adresse,
                  email_pro: this.editedItem.email_pro,
                  tel: this.editedItem.tel,
                  mobile1: this.editedItem.mobile1,
                  mobile2: this.editedItem.mobile2,
                  email_perso: this.editedItem.email_perso,
                  gps: this.editedItem.gps,
                  label: this.editedItem.label,
                },
              },
            })
            .then((data) => {
              this.editedItem.ville_name =
                data.data.updateAdress.ville.nom_ville;
              this.editedItem.departement_name =
                data.data.updateAdress.ville.departement.nom_departement;
              this.addresses.splice(this.editedItem.index, 1, this.editedItem);
              this.$store.dispatch("Changed", true);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        } else {
          this.$apollo
            .mutate({
              mutation: CREATE_ADRESS,
              variables: {
                adress: {
                  tier_id: this.tier_id,
                  ville_id: this.editedItem.ville_id,
                  adresse_type: this.editedItem.adresse_type,
                  adresse: this.editedItem.adresse,
                  email_pro: this.editedItem.email_pro,
                  tel: this.editedItem.tel,
                  mobile1: this.editedItem.mobile1,
                  mobile2: this.editedItem.mobile2,
                  email_perso: this.editedItem.email_perso,
                  gps: this.editedItem.gps,
                  label: this.editedItem.label,
                },
              },
            })
            .then((data) => {
              this.editedItem.id = data.data.createAdress.id;
              this.editedItem.ville_name =
                data.data.createAdress.ville.nom_ville;
              this.editedItem.departement_name =
                data.data.createAdress.ville.departement.nom_departement;
              this.addresses.push(this.editedItem);
              this.$store.dispatch("Changed", true);
            })
            .catch((error) => {
              this.loading = false;
              this.snackbar_text = error.message;
              this.snackbar_color = "error";
              this.snackbar = true;
            });
        }
        this.close();
      }
    },
    dept_change() {
      this.editedItem.ville_id = "";
      this.villes = this.select_ville(this.editedItem.departement_id);
    },
  },
};
</script>
